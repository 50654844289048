@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

body {
  font-family: "Poppins", sans-serif !important;
  background: #F5F5F5;
}

.tabs-bordered > .tab {
  @apply border-b-4 border-gray-300
}
.tab:is(input:checked) {
  @apply border-b-4 border-blue-500; /* Tailwind CSS classes for a blue border */
}

/* Optional: Add hover or focus styles if desired */
.tab-bordered:hover {
  @apply border-b-4 border-blue-600;
}

.btn-type1 {
  @apply py-1.5 font-normal w-full px-4 text-xs rounded-full bg-blue-100 text-primaryblue hover:bg-primaryblue/80 hover:text-white
}

.btn-p {
  @apply bg-[#0148B7] text-white rounded-full flex justify-center items-center py-1 px-3 gap-2 hover:scale-105 duration-300;
}

.btn-outline-p {
  @apply bg-none text-[#0148B7] rounded-md flex justify-center items-center duration-300 py-1 px-3 gap-2 hover:text-white hover:bg-[#0148B7] hover:scale-105;
}

.btn-sec {
  @apply  bg-secondaryblue rounded-md  text-white hover:bg-primaryblue uppercase
}



/* For Firefox */
* {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #0148B7 #dad9d9; /* For Firefox */
}

/* For WebKit-based browsers */
*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: #0148B7;
  border-radius: 5px;
}

*::-webkit-scrollbar-track {
  background-color: #dad9d9;
  border-radius: 5px;
}

/* //right-bar calendar css */
.right-bar .css-1aqny2q-MuiPickersCalendarHeader-root {
  padding-left: .5rem !important;
  margin: 0;
  height: 25px;
}

.right-bar .css-1aqny2q-MuiPickersCalendarHeader-root {
  margin-top: 0rem;
}

.right-bar .css-fgu9me-MuiDateCalendar-root {
  height: 270px;
}

.right-bar .css-i5q14k-MuiDayCalendar-header {
  padding: 2px 0px;
}

.right-bar .css-8je8zh-MuiTouchRipple-root {
  background-color: none;
}

.right-bar button,
.right-bar button:hover,
.right-bar button:focus {
  border: none;
}